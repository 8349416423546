import styled from "styled-components";

export const RegisteredUsersContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30vh;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    & > p {
      font-size: 2.7rem;
      font-weight: bold;
      color: #b8bec8;
      margin: 0;
    }
  }
  h3{
 font-size:2.2rem;
 font-weight:thin;
 margin:1.4rem 0;
 padding:0 0.6rem 0 0;
 background: -webkit-linear-gradient(20deg,#2596be
, #ffffff 60%);-webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 
  }

  p{
    font-size:1.3rem;
    color:#b8bec8;
    text-align:center;
  }
  .button {
        background: transparent;
        border-radius: 1.8rem;
        border: 3px solid #2596be
;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.9rem 1.5rem;
        transition: 0.2s;
        color: #2596be
;
        margin-top: 2rem;
      }
      button:hover {
        background: #2596be
;
        cursor: pointer;
        color: white;
        transition: 0.2s;
      }
    }

    @media screen and (max-width: 600px) { 
  margin-bottom: 20vh;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    & > p {
      font-size: 2.4rem;
    }
  }
  h3{
 font-size:2rem;
  }

  p{
    font-size:1.2rem;
    word-spacing:0.2rem;
    text-align:center;
  }
  .button {
        margin-top: 1rem;
      }
    }
    }
`;
