import React from "react";
import { InvestmentPlanContainer } from "./investmentPlan.style";
import YellowCircle from "../../../components/yellowCircle/YellowCircle";
import { BiDollar as DollarSign } from "react-icons/bi";

const InvestmentPlan = () => {
  const Card = [
    {
      title: "STARTER",
      number: "PLAN",
      currency1: 1000,
      currency2: 9999,
      dailyROI: "2.5% daily ROI",
      freeTrade: "No free traders",
      key: 0,
    },
    {
      title: "GOLD",
      number: "PLAN",
      currency1: 10000,
      currency2: 49999,
      dailyROI: "7.5% daily ROI",
      freeTrade: "5 free traders",
      key: 1,
    },
    {
      title: "PREMIUM",
      number: "PLAN",
      currency1: 50000,
      currency2: 99999,
      dailyROI: "10% daily ROI",
      freeTrade: "7 free traders",
      key: 2,
    },
    {
      title: "DIAMOND",
      number: "PLAN",
      currency1: 100000,
      currency2: ">>>",
      dailyROI: "15% daily ROI",
      freeTrade: "10 free traders",
      key: 3,
    }
  ];

  const yellowCircle = [
    { key: 1 },
    { key: 2 },
    { key: 3 },
    { key: 4 },
    { key: 5 },
    { key: 6 },
    { key: 7 },
  ];

  return (
    <InvestmentPlanContainer id="investmentPlan">
      <div className="yellowCircles">
        {yellowCircle.map(({ key }) => (
          <YellowCircle size="0.7rem" marginRight="0.4rem" key={key} />
        ))}
      </div>
      <div className="heading">
        <h3>Investment Plans</h3>
        <YellowCircle size="0.4rem" marginRight="0.2rem" />
      </div>
      <p>Our Variety of plans are designed to suit your budget</p>
      <div className="cardBox">
        {Card.map(
          ({
            key,
            title,
            number,
            currency1,
            currency2,
            dailyROI,
            freeTrade,
          }) => (
            <div className="cardContainer" key={key}>
              <div className="Card">
                <div className="titleContainer">
                  <p className="title">{title}</p>
                </div>
                <div className="numberContainer">
                  <p className="number">{number}</p>
                </div>
                <div className="currencyContainer">
                  <div className="currency">
                    <p>
                      <DollarSign />
                      {currency1} {currency2 ? " - " : null}
                      {currency2 ? <DollarSign /> : null}
                      {currency2 ? currency2 : null}
                    </p>
                  </div>
                </div>
                <div className="dailyContainer">
                  <p className="daily">{dailyROI}</p>
                </div>
                <div className="dailyContainer">
                  <p className="daily">{freeTrade}</p>
                </div>
                <div className="buttonContainer">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={() =>
                      window.open("/user/activatepackage", "_blank").focus()
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button">Start Investing</button>
                  </a>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </InvestmentPlanContainer>
  );
};

export default InvestmentPlan;
