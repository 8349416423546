import styled from "styled-components";
import Background from "../../../assets//background-bg.png";

export const HeroSectionContainer = styled("div")`
  width: 100%;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.2)
    ),
    url(${Background});
  background-size: cover;
  background-repeat: round;
  background-position: 250px 0;
  margin: 11rem 0 40vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0;

  .h1Wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    h1 {
      font-size: 4.2rem;
      font-weight: thin;
      margin: 1.4rem 0;
      padding: 0 0.6rem 0 0;
      background: -webkit-linear-gradient(20deg, #2596be
, #ffffff 60%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p {
    font-size: 1.7rem;
    margin: 0 0 3rem 0;
    padding: 0;
    color: #2596be
;
  }
  a {
    border-radius: 3rem;
    font-size: 1.5rem;
    padding: 0.9rem 1.7rem;
    color: #ffffff;
    border: 4px solid #ffffff;
    background: transparent;
    transition: 0.2s;
    text-decoration: none;
  }
  a:hover {
    color: #2596be
;
    border: 4px solid #2596be
;
    cursor: pointer;
    transition: 0.2s;
  }

  @media screen and (max-width: 900px) {
    margin: 9rem 0 15vh 0;
    align-items: center;
    .h1Wrapper {
      h1 {
        font-size: 3.5rem;
      }
    }
    p {
      font-size: 1.4rem;
      margin: 0 0 3rem 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 920px) {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 0.7)
      ),
      url(${Background});
    background-size: cover;
    background-position: 0 0;
  }

  @media screen and (max-width: 510px) {
    .h1Wrapper {
      h1 {
        font-size: 3rem;
      }
    }
    p {
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 370px) {
    .h1Wrapper {
      h1 {
        font-size: 2.3rem;
      }
    }
    p {
      font-size: 1.2rem;
    }
  }
`;
