import styled from "styled-components";

export const InvestmentPlanContainer = styled("div")`
  display: flex;
  flex-direction:column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30vh;
  width:100%;
    .yellowCircles{
display:flex;
justify-content:space-around;
align-items:center;
  }
  .heading{
display:flex;
justify-content flex-start;
align-items:baseline;
  h3{
 font-size:2rem;
 font-weight:thin;
 margin:1.4rem 0;
 padding:0 0.6rem 0 0;
 background: -webkit-linear-gradient(20deg,#2596be
, #ffffff 60%);-webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
  }
}
  
  p{
font-size:1.5rem;
margin:2rem 0 4rem 0;
color:white;
  }

  .cardBox{
      width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-around;
  flex-wrap: wrap;

.cardContainer {
    display: flex;
    flex-direction: row;
    width: 30%;
    min-width: 20rem;
    transition: 0.3s;
    margin: 1rem 1rem;

    .Card:hover {
      border-color: #2596be
;
      border: 5px solid #2596be
;
      transition: ease-in-out 0.4s;
    }

    .Card {
      width: 100%;
      border: 5px solid transparent;
      padding: 3rem 2rem;
      border-radius: 2rem;
      background-color: #131418;
      transition: 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .titleContainer {
        .title {
          color: white;
          display: flex;
          justify-content: center;
          font-size: 1.5rem;
          margin: 0;
          padding: 0;
        }
      }
      .numberContainer {
        display: flex;
        padding: 0;
        margin-bottom: 2rem;
        align-items: center;
        justify-content: center;

        .number {
          color: #b8bec8;
          margin: 0;
          padding: 0;
          font-size: 1.5rem;
          font-weight:bolder;
          align-self: center;
          align-content: center;
          justify-content: center;
        }
      }

      .currencyContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom:2rem;

        .currency {
          background: #181a20;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 1.5rem;
          p{
            font-size: 1.1rem;
            color: #2596be
;
            font-weight: bold;
            margin:1.5rem 0;
          }
        }
      }
      .dailyContainer {
        margin-top: .5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #666d7a;

        .daily {
          font-size: 1rem;
          margin: 0;
          padding: 0 0 0.4rem 0;
          font-weight: bold;
          width: 100%;
          color: #666d7a;
          display: flex;
          justify-content: center;
        }
      }

      .button {
        background: transparent;
        border-radius: 1.8rem;
        border: 3px solid #2596be
;
        font-size: 1.1rem;
        font-weight: bold;
        padding: 0.7rem 1.5rem;
        transition: 0.2s;
        color: #2596be
;
        margin-top: 2rem;
      }
      button:hover {
        background: #2596be
;
        cursor: pointer;
        color: white;
        transition: 0.2s;
      }
    }
  }
  }
  

  @media screen and (max-width: 790px) {
    padding:0 0.5rem;
 .heading{
  h3{
 font-size:1.9rem;
 margin:1.2rem 0;
 padding:0 0.3rem 0 0;
  }
}
  
  p{
font-size:1.2rem;
margin:2rem 0 2rem 0;
text-align:center;
  }
  .cardBox{
      flex-direction:column;
      .cardContainer {
    width: 90%;
    min-width: 90%;
    }
  }
  }
  }


   @media screen and (max-width: 343px) {

  .cardBox{
.cardContainer {
    .Card {
      .button {
        font-size: 0.9rem;
      }
    }
  }
  }
   }
  `;
