import React from "react";
import { FooterContainer } from "./footer.styles";
import Logo from "./../../../assets/app.png";

const Footer = () => {
  return (
    <FooterContainer>
      <div className="container">
        <img src={Logo} alt="Company Logo" />

        <p>
          Founded in the year 2015, Sterling Markets is one of the world’s leading
          independent branded companies. Headquartered in United States.
        </p>
        <p>
          We have offices in five countries. We provide a unique global
          perspective through our global network, in-depth market and sector
          knowledge.
        </p>

        <p className="bottom">&copy; All Rights Reserved 2020</p>
        <p className="bottom">Sterling Markets</p>
      </div>
    </FooterContainer>
  );
};

export default Footer;
