import React from "react";
import { ContactUsContainer } from "./contactUs.styles.jsx";

const ContactUs = () => {
  return (
    <ContactUsContainer id="contactUs">
      <h1>Contact Us </h1>

      <div className="content">
        <h2>ADDRESS</h2>
        <p>
          48 Wall Street , NY 10005, United States.
        </p>
      </div>
      <div className="content">
        <h2>MAIL US AT</h2>
        <p>support@sterlingmarkets.pro</p>
      </div>
    </ContactUsContainer>
  );
};

export default ContactUs;
