import styled from "styled-components";

export const WeeklyPayoutContainer = styled("div")`
  width:100%;
  display: flex;
  flex-direction:column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30vh;
    .yellowCircles{
display:flex;
justify-content:space-around;
align-items:center;
  }
  .heading{
display:flex;
justify-content flex-start;
align-items:baseline;
  h3{
 font-size:2.2rem;
 font-weight:thin;
 margin:1.4rem 0;
 padding:0 0.6rem 0 0;
 background: -webkit-linear-gradient(20deg,#2596be
, #ffffff 60%);-webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 
  }
}
  
  p{
font-size:1.5rem;
margin:0.5rem 0 4rem 0;
color:white;
  }

  .tableContainer{
      width: 100%;
      // background:red;
  }
  

  @media screen and (max-width: 790px) {
    padding:0 0.5rem;
 .heading{
  h3{
 font-size:1.9rem;
 margin:1.2rem 0;
 padding:0 0.3rem 0 0;
  }
}
  
  p{
font-size:1.2rem;
margin:2rem 0;
text-align:center;
  }


  .tableContainer{
  }
  }
  `;
